import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import AdminFooter from '../utils/AdminFooter'
import { baseUrl } from '../utils/Constants'
import { AccountContext } from '../auth/Account'
import Pool from '../auth/UserPool'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminAddPeopleToEventPage() {
    const location = useLocation()
    const navigation = useNavigate()
    const { logout } = useContext(AccountContext)

    const [linkFirstName, setLinkFirstName] = useState("")
    const [linkLastName, setLinkLastName] = useState("")
    const [linkEmail, setLinkEmail] = useState("")
    const [originAirport, setOriginAirport] = useState("")
    const [returnAirport, setReturnAirport] = useState("")
    const [eventId, setEventId] = useState("")
    const [eventName, setEventName] = useState("")
    const [maxCost, setMaxCost] = useState(0.00)
    const [expirationDate, setExpirationDate] = useState("")

    const [btnDisabled, setBtnDisabled] = useState(false)
    const [showEvent, setShowEvent] = useState(false)
    const [validated, setValidated] = useState(false)

    const handleEventClose = () => setShowEvent(false)
    const handleEventShow = () => setShowEvent(true)

    const [authToken, setAuthToken] = useState("")

    useEffect(() => {
        if (location.state?.event_id && location.state?.event_name) {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        navigation('/sign-in')
                    } else {
                        setAuthToken(session.accessToken.jwtToken)
                        setEventId(location.state.event_id)
                        setEventName(location.state.event_name)
                    }
                })
            }
        } else {
            navigation('/dashboard')
        }
    }, [])

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {

            e.preventDefault()
            setBtnDisabled(true)

            const linkData = {
                link_id: crypto.randomUUID(), // TODO: move this to server side generation
                link_first_name: linkFirstName,
                link_last_name: linkLastName,
                link_email: linkEmail,
                origin_airport: originAirport,
                return_airport: returnAirport,
                is_booked: false,
                order_pnr: null,
                order_id: null,
                max_cost: maxCost,
                event_id: eventId,
                expiration_date: expirationDate
            }

            fetch(baseUrl + "/admin/add-person", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(linkData)
            })
                .then(results => results.json())
                .then(data => {
                    // console.log(data)
                    setBtnDisabled(false)
                    alert("Attendee successfully added to event.")
                    navigation('/manage-event', { state: { event_id: eventId } })
                })
                .catch(error => {
                    console.log(error)
                    setBtnDisabled(false)
                })
        }
        setValidated(true)
    }

    function handleLogout() {
        logout()
        navigation('/sign-in')
    }

    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Add Attendee</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Button variant="success" className="log-in-button" style={{ marginRight: '32px' }} onClick={handleEventShow}>
                            Back to Event
                        </Button>
                        <Button variant="dark" className="log-in-button" onClick={() => handleLogout()}>
                            Log Out
                        </Button>
                    </Navbar.Collapse>
                </Navbar>
                <div className='admin-dashboard-landing'>
                    <h3>Add attendee to event</h3>
                    <Row>
                        <Col />
                        <Col md={6}>
                            <div className='dark-rect-round'>
                                <Form noValidate onSubmit={(e) => handleSubmit(e)} validated={validated}>
                                    <Form.Group className="mb-3" controlId='formBasicFirstName' onChange={(e) => { setLinkFirstName(e.target.value) }}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" required />
                                        <Form.Control.Feedback type='invalid'>Please provide a first name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicLastName' onChange={(e) => { setLinkLastName(e.target.value) }}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type='text' placeholder='Last Name' required />
                                        <Form.Control.Feedback type='invalid'>Please provide a last name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setLinkEmail(e.target.value) }}>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type='email' placeholder='Email Address' required />
                                        <Form.Control.Feedback type='invalid'>Please provide a valid email address.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicOriginAirport' onChange={(e) => { setOriginAirport(e.target.value) }}>
                                        <Form.Label>Origin Airport</Form.Label>
                                        <Form.Control type='text' placeholder='JFK' required />
                                        <Form.Control.Feedback type='invalid'>Please provide a valid origin airport code.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicReturnAirport' onChange={(e) => { setReturnAirport(e.target.value) }}>
                                        <Form.Label>Return Airport</Form.Label>
                                        <Form.Control type='text' placeholder='JFK' required />
                                        <Form.Control.Feedback type='invalid'>Please provide a valid return airport code.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicMaxBudget' onChange={(e) => { setMaxCost(e.target.value) }}>
                                        <Form.Label>Maximum Ticket Cost (Round Trip)</Form.Label>
                                        <Form.Control type='number' step='0.01' placeholder='100' min='0' required></Form.Control>
                                        <Form.Control.Feedback type='invalid'>Please provide a valid amount.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicExpirationDate' onChange={(e) => { setExpirationDate(e.target.value) }}>
                                        <Form.Label>Expiration Date</Form.Label>
                                        <Form.Control type="date" placeholder='Expiration Date' required />
                                        <Form.Control.Feedback type="invalid">Please provide an expiration date.</Form.Control.Feedback>
                                    </Form.Group>
                                    <div className='text-align-center'>
                                        <Button variant='success' type='submit' className='log-in-button' disabled={btnDisabled}>
                                            Add Attendee
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                        <Col />
                    </Row>
                </div>
                <hr />
                <AdminFooter />
            </Container>
            <Modal show={showEvent} onHide={handleEventClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Return to Event?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Changes will not be saved.</Modal.Body>
                <Modal.Footer>
                    <Link to="/manage-event" className="no-style-link-light" state={{ event_id: eventId }}>
                        <Button variant="primary" onClick={handleEventClose}>
                            Confirm
                        </Button>
                    </Link>
                    <Button variant="secondary" onClick={handleEventClose}>
                        Keep Editing
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminAddPeopleToEventPage
