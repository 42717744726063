import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../utils/Footer'

import bookFlightImg from '../assets/book_flight.png'
import manageEventImg from '../assets/manage_event.png'
import genCatalystLogo from '../assets/general_catalyst.png'
// import rdvLogo from '../assets/rdv.png'
import pearLogo from '../assets/Pear-Logo.svg'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LandingPage.css';

function LandingPage() {
    return (
        <div>
            <div className='home'>
                <Container>
                    <Navbar className='navbar-no-bg'>
                        <Navbar.Brand href="/" className='landing-text'>✈️ <b>Planelinks</b></Navbar.Brand>
                        <Navbar.Collapse className='justify-content-end'>
                            <Container>
                                <Nav className="me-auto">
                                    <Nav.Link href="#features" className='landing-text navbar-margin'>Features</Nav.Link>
                                    <Nav.Link href="/book-demo" className='landing-text navbar-margin'>Book Demo</Nav.Link>
                                    <Nav.Link href="/pricing" className='landing-text navbar-margin'>Pricing</Nav.Link>
                                </Nav>
                            </Container>
                        </Navbar.Collapse>
                        <Link to="/sign-in" className='no-style-link d-flex landing-text navbar-margin'>Log In</Link>
                        <Link to="/sign-up" className='no-style-link d-flex'>
                            <Button variant='dark' className='log-in-button'>
                                Sign Up
                            </Button>
                        </Link>
                    </Navbar>
                    <div className='landing-text'>
                        {/* <div style={{ marginTop: "6%" }}>
                            <Link to="/blog" style={{ textDecoration: "none", color: "black" }}>
                                <span class="landing-callout">Planelinks runs flights logistics for the largest fashion shows. <span className="callout-learn-more">Learn more.</span></span>
                            </Link>
                        </div> */}
                        {/* <h1 className='h1-name' style={{ marginTop: "3%" }}> */}
                        <h1 className='h1-name' style={{ marginTop: "10%" }}>
                            <strong>
                                Large scale group travel, <br /><div style={{ color: "darkblue" }}>simplified.</div>
                            </strong>
                        </h1>
                        <p className='h2-slogan' style={{ marginTop: "3%" }}>
                            Planelinks helps you coordinate flights for large scale, in-person events.
                        </p>
                        <div style={{ marginTop: '2%' }}>
                            <Link to="/book-demo" className='no-style-link'>
                                <Button variant="light" className="get-started-button" style={{ margin: '8px' }}>
                                    Book Demo
                                </Button>
                            </Link>
                            <Link to="/sign-up" className='no-style-link'>
                                <Button variant='dark' className="get-started-button" style={{ margin: '8px' }}>
                                    Get Started
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
            <Container id="features">
                <h1 className="text-center h1-name">Get Link, Book Travel, Done.</h1>
                <div className='admin-dashboard-landing' style={{ marginTop: "5vh" }}>
                    <Row>
                        <Col md={4}>
                            <div style={{ marginTop: "5%" }}>
                                <h3>It's time to ditch the back-and-forth emails, complicated travel policies, and tedious reimbursement processes.</h3>
                                <p>
                                    The Planelinks dashboard is <b>endlessly customizable</b> to your events' needs. Easily manage travel logistics and
                                    set custom flight restrictions, origin airports, and dates for each individual attendee.
                                </p>
                                <p>
                                    After booking a flight, the organizer is charged directly for the travel, eliminating the need to hunt down receipts,
                                    store bank account information, and process reimbursements by hand.
                                </p>
                            </div>
                        </Col>
                        <Col />
                        <Col md={7}>
                            <div className="blue-rect-round" style={{ marginTop: "12%" }}>
                                <img src={manageEventImg} alt="Manage Event Dashboard" width="100%" />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='admin-dashboard-landing'>
                    <Row>
                        <Col md={7}>
                            <div className='blue-rect-round'>
                                <img src={bookFlightImg} alt="Book Flight Page" width="100%" />
                            </div>
                        </Col>
                        <Col />
                        <Col md={4}>
                            <div style={{ marginTop: "20%" }}>
                                <h3>Give attendees flexibility to choose travel that works best for them, while staying within your budget.</h3>
                                <p>
                                    Planelinks will generate unique booking links for each attendee. Only flights
                                    that comply with the rules and restrictions you set will be eligible for booking in the attendee portal.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <h1 className="text-center h1-action">In-person event management<br />has never been easier.</h1>
                <div className='text-align-center'>
                    <Link to="/sign-up" className='no-style-link'>
                        <Button variant='dark' className="get-started-button">
                            Get started
                        </Button>
                    </Link>
                </div>
            </Container>
            <Container>
                <div className="admin-dashboard-landing">
                    <h3 className="text-align-center" style={{ marginBottom: '5vh' }}>Supported By</h3>
                    <div className="text-center">
                        <img src={genCatalystLogo} alt="General Catalyst" style={{ width: "220px", padding: "0px 16px 16px 8px" }} />
                        {/* <img src={rdvLogo} alt="Rough Draft Ventures" style={{ height: "100px", padding: "0px 16px 16px 8px" }} /> */}
                        <img src={pearLogo} alt="Pear VC" style={{ width: "200px", padding: "0px 8px 16px 16px" }} />
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default LandingPage
