import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../utils/Footer'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function PricingPage() {
    return (
        <div className='home'>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/" className='landing-text'>✈️ <b>Planelinks</b></Navbar.Brand>
                    <Navbar.Collapse className='justify-content-end'>
                        <Container>
                            <Nav className="me-auto">
                                <Nav.Link href="/#features" className='landing-text navbar-margin'>Features</Nav.Link>
                                <Nav.Link href="/book-demo" className='landing-text navbar-margin'>Book Demo</Nav.Link>
                                <Nav.Link href="/pricing" className='landing-text navbar-margin'>Pricing</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar.Collapse>
                    <Link to="/sign-in" className='no-style-link d-flex landing-text navbar-margin'>Log In</Link>
                    <Link to="/sign-up" className='no-style-link d-flex'>
                        <Button variant='dark' className='log-in-button'>
                            Sign Up
                        </Button>
                    </Link>
                </Navbar>
                <div className='landing-text'>
                    <h1 className="text-center h1-name">Pricing</h1>
                </div>
                <br />
                <Row>
                    <Col md={6}>
                        <div className='grey-rect-no-padding' style={{ minHeight: "450px", padding: "8px" }}>
                            <div className='text-align-center'>
                                <h3>Friends</h3>
                                <p><strong>Help the trip make it out of the group chat.</strong></p>
                            </div>
                            <div className='text-align-center'>
                                <Link to="/sign-up" className='no-style-link'>
                                    <Button variant='dark' className="get-started-button" style={{ margin: '8px' }}>
                                        Start for Free
                                    </Button>
                                </Link>
                            </div>
                            <br />
                            <ul>
                                <li>Free to use</li>
                                <li>Unlimited simultaneous events</li>
                                <li>Unlimited number of people per event</li>
                                <li>Basic payment features (organizer pays all upfront or none upfront)</li>
                                <li>Advanced payment features (split payments)</li>
                                <li>Email based support only</li>
                            </ul>

                        </div>
                    </Col>
                    {/* <Col md={4}>
                        <div className='grey-rect-no-padding' style={{ minHeight: "450px", padding: "8px" }}>
                            <div className='text-align-center'>
                                <h3>Events</h3>
                                <p><strong>For larger events, pay as you go.</strong></p>
                            </div>
                            <div className='text-align-center'>
                                <Link to="/sign-up" className='no-style-link'>
                                    <Button variant='dark' className="get-started-button" style={{ margin: '8px' }}>
                                        Get Started
                                    </Button>
                                </Link>
                            </div>
                            <br />
                            <ul>
                                <li>$25 per link generated</li>
                                <li>6 simultaneous events</li>
                                <li>200 people per event</li>
                                <li>Advanced payment features (split payments)</li>
                                <li>Email and phone based support</li>
                            </ul>
                        </div>
                    </Col> */}
                    <Col md={6}>
                        <div className='grey-rect-no-padding' style={{ minHeight: "450px", padding: "8px" }}>
                            <div className='text-align-center'>
                                <h3>Enterprises</h3>
                                <p><strong>Custom solutions for your event's needs.</strong></p>
                            </div>
                            <div className='text-align-center'>
                                <Link to="/book-demo" className='no-style-link'>
                                    <Button variant='dark' className="get-started-button" style={{ margin: '8px' }}>
                                        Book Demo
                                    </Button>
                                </Link>
                            </div>
                            <br />
                            <ul>
                                <li>Custom pricing per link generated</li>
                                <li>Commission profit sharing with your event</li>
                                <li>Unlimited simultaneous events</li>
                                <li>Unlimited people per event</li>
                                <li>Custom logo, brand placement, and URL link</li>
                                <li>Advanced payment features (split payments)</li>
                                <li>Email, phone, and in-person support live at your event</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className='grey-rect-round text-align-center'>
                            <h3>Ready to get started?</h3>
                            <p>Book a demo now</p>
                            <Link to="/book-demo" className='no-style-link'>
                                <Button variant='dark' className="get-started-button" style={{ margin: '8px' }}>
                                    Book Demo
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default PricingPage
