import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import AdminFooter from "../utils/AdminFooter"
import { baseUrl } from "../utils/Constants"
import Pool from '../auth/UserPool'
import { AccountContext } from "../auth/Account"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminDashboardLandingPage() {
    const navigation = useNavigate()
    const { logout } = useContext(AccountContext)

    const [userName, setUserName] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [organizationID, setOrganizationID] = useState(undefined)
    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [pastEvents, setPastEvents] = useState([])

    const [authToken, setAuthToken] = useState("")

    useEffect(() => {
        // Load email and organization name from current session token (opening dashboard in new tab)
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    navigation('/sign-in')
                } else {
                    setAuthToken(session.accessToken.jwtToken)
                    setUserName(session.idToken.payload.email)
                    setOrganizationName(session.idToken.payload["custom:organization_name"])
                    setOrganizationID(session.idToken.payload["cognito:username"])
                }
            })
        } else {
            navigation('/sign-in')
        }
    }, [])

    useEffect(() => {
        if (organizationID && authToken) {
            fetch(baseUrl + '/admin/get-upcoming-events?' + new URLSearchParams({
                organizer_id: organizationID
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            })
                .then(results => results.json())
                .then(data => {
                    setUpcomingEvents(data)
                });
        }
    }, [organizationID, authToken])

    useEffect(() => {
        if (organizationID && authToken) {
            fetch(baseUrl + '/admin/get-past-events?' + new URLSearchParams({
                organizer_id: organizationID
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            })
                .then(results => results.json())
                .then(data => {
                    setPastEvents(data)
                })
        }
    }, [organizationID, authToken])

    function handleLogout() {
        logout()
        navigation('/sign-in')
    }

    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Dashboard</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Button variant="dark" className="log-in-button" onClick={() => handleLogout()}>
                            Log Out
                        </Button>
                    </Navbar.Collapse>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <p>Email: {userName}<br />
                        Organization: {organizationName}</p>
                    <Link to="/create-new-event" className="no-style-link-light" state={{ organizationName: organizationName, organizationID: organizationID, userName: userName }}>
                        <Button variant="success" className="log-in-button" style={{ marginTop: '16px' }}>
                            Create New Event
                        </Button>
                    </Link>
                </div>
                <h3 style={{ paddingTop: '32px' }}>Upcoming and Current Events:</h3>
                <section className="card--scroll">
                    {upcomingEvents.map(function (event) {
                        return (
                            <div className="card--content" key={event.event_id}>
                                <Card>
                                    <Card.Img variant="top" src={event.event_img} />
                                    <Card.Body>
                                        <Card.Title>{event.event_name}</Card.Title>
                                        <Card.Text>
                                            {event.event_location}<br />
                                            {event.event_start} to {event.event_end}
                                        </Card.Text>
                                        <Link to="/manage-event" className="no-style-link-light" state={{ event_id: event.event_id }}>
                                            <Button variant="dark">
                                                Manage Event
                                            </Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    })}
                </section>
                <hr />
                <h3 style={{ paddingTop: '32px' }}>Past Events:</h3>
                <section className="card--scroll">
                    {pastEvents.map(function (event) {
                        return (
                            <div className="card--content" key={event.event_id}>
                                <Card>
                                    <Card.Img variant="top" src={event.event_img} />
                                    <Card.Body>
                                        <Card.Title>{event.event_name}</Card.Title>
                                        <Card.Text>
                                            {event.event_location}<br />
                                            {event.event_start} to {event.event_end}
                                        </Card.Text>
                                        <Link to="/view-event" className="no-style-link-light" state={{ event_id: event.event_id, userName: userName, organizationName: organizationName }}>
                                            <Button variant="dark">
                                                View Event
                                            </Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    })}
                </section>
                <hr />
                <AdminFooter />
            </Container>
        </div>
    )
}

export default AdminDashboardLandingPage
