import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"

import { baseUrl, pricePerLink } from "../utils/Constants"
import AdminLinksCheckoutForm from '../utils/AdminLinksCheckoutForm'

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminAddLinksPage() {
    const location = useLocation()
    const navigation = useNavigate()

    const numLinks = location.state.num_links
    const amountOwed = numLinks * pricePerLink
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")

    useEffect(() => {
        if (amountOwed > 0) {
            fetch(baseUrl + "/client/stripe-config")
                .then(results => results.json())
                .then(data => {
                    setStripePromise(loadStripe(data.publishableKey))
                })

            fetch(baseUrl + "/client/create-payment-intent?" + new URLSearchParams({
                amount: Math.floor(amountOwed * 100),
                email: location.state.email,
                description: "Additional Links",
            }), {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(results => results.json())
                .then(data => {
                    setClientSecret(data.clientSecret)
                })
        }
    }, [amountOwed])

    return (
        <div>
            <Container>
                <Navbar className="navbar-no-bg">
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Add Additional Links</Navbar.Brand>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <Row>
                        <Col />
                        <Col lg={6} md={9}>
                            <div className="admin-dashboard-landing">
                                <h3>Add and Pay For Additional Links</h3>
                                <p>Please pay for the remaining portion below.</p>
                                <p>Number of new links: {numLinks}<br />
                                    Amount Due: ${amountOwed}</p>
                                <p>Need to make changes?<Button variant="link" onClick={() => navigation(-1)}>Go back</Button></p>
                                {stripePromise && clientSecret && (
                                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <AdminLinksCheckoutForm />
                                    </Elements>
                                )}
                            </div>
                        </Col>
                        <Col />
                    </Row>
                </div>
                <div className="admin-dashboard-landing">
                    <br />
                    <br />
                </div>
            </Container>
        </div>
    )
}

export default AdminAddLinksPage
