import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Pool from '../auth/UserPool'

const Status = () => {
    const user = Pool.getCurrentUser()

    if (user) {
        return <Outlet />
    } else {
        return <Navigate to="/sign-in" />
    }
}

export default Status
