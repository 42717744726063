import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { AccountContext } from '../auth/Account'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LandingPage.css';
import '../styles/SignInPage.css'

function SignInPage() {
    const { authenticate } = useContext(AccountContext)
    const navigation = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validated, setValidated] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState("")

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            authenticate(email, password)
                .then(data => {
                    navigation('/dashboard', { state: { organizationId: data.idToken.payload["cognito:username"], organizationName: data.idToken.payload["custom:organization_name"], email: data.idToken.payload.email } })
                })
                .catch(err => {
                    setLoginErrorMessage(err.toString())
                })
        }
        setValidated(true)
    }

    return (
        <div className='home'>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/" className='landing-text'>✈️ <b>Planelinks</b></Navbar.Brand>
                </Navbar>
                <div className='landing-text'>
                    <p className='slogan'>
                        Welcome back, please log in below.
                    </p>
                </div>
                <Row>
                    <Col />
                    <Col>
                        <div className='grey-rect-round' style={{ minHeight: "30vh", width: '25em' }}>
                            <Form noValidate onSubmit={(e) => { handleSubmit(e) }} validated={validated}>
                                <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setEmail(e.target.value) }}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder='Email Address' required />
                                    <Form.Control.Feedback type='invalid'>Please provide a valid email address.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='formBasicPassword' onChange={(e) => { setPassword(e.target.value) }}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder='Password' required />
                                    <Form.Control.Feedback type='invalid'>Please provide a password.</Form.Control.Feedback>
                                    <Form.Text><Link to="/reset-password" className='no-style-link'>Forgot password?</Link></Form.Text>
                                </Form.Group>
                                <div style={{ color: "red" }}>{loginErrorMessage}</div>
                                <div className='text-align-center'>
                                    <Button variant='dark' type='submit' className='sign-in-button'>
                                        Sign In
                                    </Button>
                                    <br />
                                    <br />
                                    <Form.Text>Don't have an account? <Link to="/sign-up" className='no-style-link'>Sign Up</Link></Form.Text>
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col />
                </Row>
            </Container>
        </div>
    )
}

export default SignInPage
