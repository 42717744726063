import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from "react-bootstrap/Button"
import Form from 'react-bootstrap/Form'
import { calculateLayover, transformDuration, convertDateFormat, displayDetailedFlightInformation } from '../utils/utils'
import { baseUrl } from "../utils/Constants"
import ClientFooter from "../utils/ClientFooter"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'
import '../styles/BookingPage.css'

function ClientBookingPage() {
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")
    const navigation = useNavigate()

    const { t } = useTranslation()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [eventName, setEventName] = useState("")
    const [requestId, setRequestId] = useState("")
    const [passengerId, setPassengerId] = useState("")
    const [maxCost, setMaxCost] = useState(0.0)
    const [flightOptions, setFlightOptions] = useState([])
    const [returnFlightOptions, setReturnFlightOptions] = useState([])

    const [outboundFlight, setOutboundFlight] = useState("")
    const [returnFlight, setReturnFlight] = useState("")
    const [hasOutbound, setHasOutbound] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [hasReturn, setHasReturn] = useState(false)
    const [fares, setFares] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        fetch(baseUrl + '/client/get-person?' + new URLSearchParams({
            link_id: id
        }))
            .then(results => {
                if (results.status === 400 || results.status === 404) {
                    return navigation('/invalid-expired-link')
                }
                return results.json()
            })
            .then(data => {
                if (data && data.order_id) {
                    navigation('/booking-finish', { state: { link_id: id } })
                } else if (data) {
                    setFirstName(data.link_first_name)
                    setLastName(data.link_last_name)
                    setEmail(data.link_email)
                    setOrganizationName(data.event_organizer)
                    setEventName(data.event_name)

                    setIsLoading(true)
                    fetch(baseUrl + '/client/get-eligible-flights?' + new URLSearchParams({
                        link_id: id
                    }))
                        .then(results => results.json())
                        .then(data => {
                            setRequestId(data.request_id)
                            setPassengerId(data.passenger_id)
                            setFlightOptions(data.flights_list)
                            setMaxCost(data.max_cost)
                            setIsLoading(false)
                        })
                }
            })
    }, [])

    function handleOutboundSelection(offer_id, flight) {
        setIsLoading(true)
        fetch(baseUrl + '/client/get-return-flights?' + new URLSearchParams({
            request_id: requestId,
            offer_id: offer_id,
            origin: flight.destination_airport
        }))
            .then(results => results.json())
            .then(data => {
                setOutboundFlight(flight)
                setReturnFlightOptions(data)
                setHasOutbound(true)
                setHasReturn(false)
                setIsLoading(false)
            })
    }

    function handleReturnSelection(flight) {
        setReturnFlight(flight)
        setHasReturn(true)
        setIsLoading(true)

        const fareInput = {
            outbound_flight: outboundFlight,
            return_flight: flight,
            request_id: requestId,
            link_id: id,
        }
        fetch(baseUrl + '/client/get-fare-types', {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fareInput)
        })
            .then(results => results.json())
            .then(data => {
                console.log(data)
                setFares(data)
            })
    }

    return (
        <div>
            <Container>
                <Navbar className="navbar-no-bg">
                    <Navbar.Brand>✈️ <b>Planelinks</b> | {t("book_flight_navbar")}</Navbar.Brand>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <h3>{t("welcome")}, {firstName} {lastName}<br />
                        {t("invited")} {eventName}</h3>
                    <p>{t("make_selection")}</p>
                    {/* The organizer {organizationName} will be billed automatically for your flights.</p> */}
                </div>
                <div>
                    <h3>{t("outbound_flights")}</h3>
                    <p>{t("select_one")}</p>
                    {
                        flightOptions.length === 0 ? <p>Loading...</p> :
                            <Accordion className="flight-options" alwaysOpen>
                                {flightOptions.map(function (flight) {
                                    return (
                                        <Accordion.Item eventKey={flight.offer_id} key={flight.offer_id}>
                                            <Accordion.Header>
                                                <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.airline_img} alt="Airline Logo" /></div>
                                                <div className="individual-flight-option">
                                                    <div><b>{convertDateFormat(flight.departure_time)} - {convertDateFormat(flight.arrival_time)}</b><br />{flight.airline_name} · {flight.market_flight_no.join(", ")}</div>
                                                    <div><b>{transformDuration(flight.duration)}</b><br />{flight.origin_airport}-{flight.destination_airport}</div>
                                                    <div>{calculateLayover(flight.layover_airport)}</div>
                                                    {/* <td><p>{flight.price - maxCost <= 0 ? "✔️ Compliant" : <>❌ ${Math.ceil((flight.price - maxCost) * 100) / 100}<br />extra</>}</p></td> */}
                                                    <div>{flight.price - maxCost <= 0 ? <>✔️ Compliant<br />${flight.price}</> : <>❌ Noncompliant<br />${flight.price}</>}</div>
                                                    <div style={{ verticalAlign: "middle" }}><Form.Check type='radio' label="Select" name="dept-group" value={flight.offer_id} onClick={(e) => { e.stopPropagation(); handleOutboundSelection(e.target.value, flight) }} /></div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {displayDetailedFlightInformation(flight)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                    }
                </div>
                <div className="admin-dashboard-landing">
                    <h3>{t("return_flights")}</h3>
                    <p>{t("select_one")}</p>
                    {
                        returnFlightOptions.length === 0 ? (isLoading ? <p>Loading...</p> : <p>Please select an outbound flight above first.</p>) :
                            <Accordion className="flight-options">
                                {returnFlightOptions.map(function (flight) {
                                    return (
                                        <Accordion.Item eventKey={flight.offer_id}>
                                            <Accordion.Header>
                                                <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.airline_img} alt="Airline Logo" /></div>
                                                <div className="individual-flight-option">
                                                    <div><b>{convertDateFormat(flight.departure_time)} - {convertDateFormat(flight.arrival_time)}</b><br />{flight.airline_name} · {flight.market_flight_no.join(", ")}</div>
                                                    <div><b>{transformDuration(flight.duration)}</b><br />{flight.origin_airport}-{flight.destination_airport}</div>
                                                    <div>{calculateLayover(flight.layover_airport)}</div>
                                                    {/* <td><p>{flight.price - maxCost <= 0 ? "✔️ Compliant" : <>❌ ${Math.ceil((flight.price - maxCost) * 100) / 100}<br />extra</>}</p></td> */}
                                                    <div>{flight.price - maxCost <= 0 ? <>✔️ Compliant<br />${flight.price}</> : <>❌ Noncompliant<br />${flight.price}</>}</div>
                                                    <div style={{ verticalAlign: "middle" }}><Form.Check type='radio' label="Select" name="ret-group" value={flight.offer_id} onClick={(e) => { e.stopPropagation(); handleReturnSelection(flight) }} /></div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {displayDetailedFlightInformation(flight)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })}
                            </Accordion>
                    }
                </div>
                <div className="admin-dashboard-landing">
                    <h3>Fare Type:</h3>
                    <p>{t("select_one")}</p>
                    <section className="card--scroll">
                        {fares.map(function (fare) {
                            return (
                                <div className="card--content" key={fare.id}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>{fare.fare_type}</Card.Text>
                                            <Card.Title>{fare.fare_marketing_name}</Card.Title>
                                            <Card.Text>
                                                <ul>
                                                    <li>{fare.change_before_departure_allowed === true ? "Change allowed. Fee: " + fare.change_before_departure_amount + " " + fare.change_before_departure_currency : "Change not allowed"}</li>
                                                    <li>{fare.refund_before_departure_allowed === true ? "Refund allowed. Fee: " + fare.refund_before_departure_amount + " " + fare.refund_before_departure_currency : "Refund not allowed"}</li>
                                                    <li>{fare.carry_on_allowed === true ? fare.carry_on_allowed_amount + " carry on included" : "Carry on not allowed"}</li>
                                                    <li>{fare.checked_bag_allowed === true ? fare.checked_bag_allowed_amount + " checked bag included" : "Checked bag not allowed"}</li>
                                                    <li>Price: ${parseFloat(fare.price).toFixed(2)}</li>
                                                    <li>{fare.price - maxCost <= 0 ? "✔️ Compliant" : "❌ Noncompliant"}</li>
                                                </ul>
                                            </Card.Text>
                                            <div className="text-align-center">
                                                <Link to="/booking-info" className="no-style-link-light" state={{ firstName: firstName, lastName: lastName, email: email, outboundFlight: outboundFlight, returnFlight: returnFlight, fare: fare, id: id, passengerId: passengerId, requestId: requestId, eventName: eventName, organizationName: organizationName }}>
                                                    <Button variant="success" className="log-in-button" disabled={!hasReturn || !hasOutbound}>
                                                        {t("confirm_button")}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        })}
                    </section>
                </div>
                {/* <div className="admin-dashboard-landing" style={{ marginBottom: "5%" }}>
                    <h3>{t("confirm")}</h3>
                    <div className="text-align-center">
                        <Link to="/booking-info" className="no-style-link-light" state={{ firstName: firstName, lastName: lastName, email: email, outboundFlight: outboundFlight, returnFlight: returnFlight, id: id, passengerId: passengerId, requestId: requestId, eventName: eventName, organizationName: organizationName }}>
                            <Button variant="success" className="log-in-button" disabled={!hasReturn || !hasOutbound}>
                                {t("confirm_button")}
                            </Button>
                        </Link>
                    </div>
                </div> */}
                <hr />
                <ClientFooter />
            </Container >
        </div >
    )
}

export default ClientBookingPage
