import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function NotFoundPage() {
    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | 404 Page Not Found</Navbar.Brand>
                </Navbar>
                <div className='admin-dashboard-landing'>
                    <h3>Page not found!</h3>
                    <div className='text-align-center'>
                        <Link to="/" className="no-style-link-light">
                            <Button variant='success' className='log-in-button' style={{ marginTop: '5vh' }}>
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default NotFoundPage
