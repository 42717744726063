import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import UserPool from '../auth/UserPool'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LandingPage.css';
import '../styles/SignInPage.css'

function SignUpPage() {
    const navigation = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [validated, setValidated] = useState(false)

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            UserPool.signUp(email, password, [{ Name: "custom:organization_name", Value: organizationName }], null, (err, data) => {
                if (err) {
                    console.log(err)
                } else {
                    // console.log(data)
                    alert("Account successfully created.")
                    navigation('/sign-in')
                }
            })
        }
        setValidated(true)
    }

    return (
        <div className='home'>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/" className='landing-text'>✈️ <b>Planelinks</b></Navbar.Brand>
                </Navbar>
                <div className='landing-text'>
                    <p className='slogan'>
                        Welcome! Please create new account below.
                    </p>
                </div>
                <Row>
                    <Col />
                    <Col>
                        <div className='grey-rect-round' style={{ minHeight: "30vh", width: '25em' }}>
                            <Form noValidate onSubmit={(e) => handleSubmit(e)} validated={validated}>
                                <Form.Group className='mb-3' controlId='formBasicOrg' onChange={(e) => { setOrganizationName(e.target.value) }}>
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control type="text" placeholder='Organization Name' required />
                                    <Form.Control.Feedback type='invalid'>Please provide an organization name.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setEmail(e.target.value) }}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder='Email Address' required />
                                    <Form.Control.Feedback type='invalid'>Please provide a valid email address.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='formBasicPassword' onChange={(e) => { setPassword(e.target.value) }}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder='Password' required />
                                    <Form.Control.Feedback type='invalid'>Please choose a password.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Check type='checkbox' required label={<span>I agree to Planelink's <Link to="/terms-of-use" target='_blank'>Terms of Use</Link> and <br/><Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>.</span>}/>
                                <div className='text-align-center'>
                                    {/* <Button variant='dark' type='submit' className='sign-in-button'>
                                        Create Account
                                    </Button> */}
                                    <span style={{color: "red"}}>New account creation currently disabled.</span>
                                    <br />
                                    <br />
                                    <Form.Text>Already have an account? <Link to="/sign-in" className='no-style-link'>Sign In</Link></Form.Text>
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col />
                </Row>
            </Container>
        </div>
    )
}

export default SignUpPage
