import { useEffect, useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import Button from "react-bootstrap/Button"

import { baseUrl } from "./Constants"

import Pool from '../auth/UserPool'

import { useStripe, useElements } from "@stripe/react-stripe-js"
import { PaymentElement } from "@stripe/react-stripe-js"


export default function AdminCheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()
    const navigation = useNavigate()
    const location = useLocation()

    const paid_links = {
        num_paid_links: location.state.num_links.toString(),
        event_id: location.state.event_id,
    }
    
    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [authToken, setAuthToken] = useState("")

    useEffect(() => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    navigation('/sign-in')
                } else {
                    setAuthToken(session.accessToken.jwtToken)
                }
            })
        } else {
            navigation('/sign-in')
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setIsProcessing(true)

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/manage-event`
            },
            redirect: "if_required"
        })

        if (error) {
            setMessage(error.message)
        } else if (paymentIntent.status === "succeeded") {
            setIsProcessing(true)
            fetch(baseUrl + "/admin/add-paid-links", {
                method: "PUT",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(paid_links)
            })
                .then(results => results.json())
                .then(data => {
                    navigation("/manage-event", {state: {event_id: location.state.event_id}})
                    setIsProcessing(false)
                })
                .catch(error => alert(error))
        } else {
            setMessage("Error: unexpected state")
        }

        setIsProcessing(false)
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="text-align-center" style={{ marginTop: "2vh" }}>
                <Button disabled={isProcessing} type="submit">
                    {isProcessing ? "Processing ... " : "Pay Now"}
                </Button>
            </div>
            <div id="payment-message">{message}</div>
        </form>
    )
}
