import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Navbar from "react-bootstrap/Navbar"

import CheckoutForm from "../utils/CheckoutForm"
import ClientFooter from "../utils/ClientFooter"
import { baseUrl } from "../utils/Constants"
import { sendConfirmEmail } from "../utils/utils"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import { DuffelPayments } from "@duffel/components";

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function ClientHoldPayPage() {
    const location = useLocation()
    const navigation = useNavigate()

    const { t } = useTranslation()

    const bookingData = location.state.booking_data
    const firstName = location.state.firstName
    const lastName = location.state.lastName
    const email = location.state.email
    const eventName = location.state.eventName
    const outboundFlight = location.state.outboundFlight
    const returnFlight = location.state.returnFlight
    const id = location.state.id

    const [amountOwed, setAmountOwed] = useState(-1.0)
    const [payNowPercent, setPayNowPercent] = useState(-1.0)
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")
    const [duffelIntentToken, setDuffelIntentToken] = useState("")
    const [message, setMessage] = useState(null)

    // Disable payments button after paying (to prevent double charging)
    const [allButtonsDisabled, setAllButtonsDisabled] = useState(false)

    useEffect(() => {
        fetch(baseUrl + "/client/get-amount-owed?" + new URLSearchParams({
            link_id: location.state.id
        }))
            .then(results => results.json())
            .then(data => {
                setPayNowPercent(data.pay_now_percent)
                const owed = location.state.fare.price - (data.max_cost * data.pay_now_percent * 0.01)
                const owedRounded = Math.ceil(owed * 100) / 100
                if (owed < 0) {
                    setAmountOwed(0.0)
                } else {
                    setAmountOwed(owedRounded)
                }
            })
            .catch(error => alert(error))
    }, [])

    useEffect(() => {
        if (amountOwed > 0) {
            // Stripe payment widget (pay Planelinks, then pay airline)
            if (payNowPercent == 0) {
                // Duffel payments widget (direct payment to airline)
                fetch(baseUrl + "/client/create-duffel-intent?" + new URLSearchParams({
                    amount: amountOwed
                }))
                    .then(results => results.json())
                    .then(data => {
                        console.log(data)
                        setDuffelIntentToken(data)
                    })
            } else {
                fetch(baseUrl + "/client/stripe-config")
                    .then(results => results.json())
                    .then(data => {
                        setStripePromise(loadStripe(data.publishableKey))
                    })

                fetch(baseUrl + "/client/create-payment-intent?" + new URLSearchParams({
                    amount: Math.floor(amountOwed * 100),
                    email: location.state.email,
                    description: "Flights Payment",
                }), {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(results => results.json())
                    .then(data => {
                        setClientSecret(data.clientSecret)
                    })
            }
        }
    }, [amountOwed, payNowPercent])

    function handleSuccessfulPayment() {
        // Disable payments button (to prevent double charging)
        setAllButtonsDisabled(true)

        // Confirm payment intent
        fetch(baseUrl + "/client/confirm-duffel-intent?" + new URLSearchParams({
            payment_intent_id: duffelIntentToken.id
        }))

        // Create order
        console.log(bookingData)
        fetch(baseUrl + "/client/create-flight-booking", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bookingData)
        })
            .then(results => results.json())
            .then(data => {
                const email_data = {
                    firstName: firstName,
                    lastName: lastName,
                    user_email: email,
                    eventName: eventName,
                    order_pnr: data.order_pnr,
                    outboundFlight: outboundFlight,
                    returnFlight: returnFlight,
                }
                sendConfirmEmail(email_data)

                // Navigate to booking confirmation page
                navigation("/booking-finish", { state: { link_id: location.state.id } })
            })
            .catch(error => {
                console.error(error.message)
                setMessage("An error has occured. Please do not refresh the page or go back. Please email support@planelinks.com and we'll assist you with your booking. Please include the booking code: " + id)
            })
    }

    function handleFailurePayment(error) {
        // Display error message from Stripe
        setMessage(error.message)
    }

    return (
        <div>
            <Container>
                <Navbar className="navbar-no-bg">
                    <Navbar.Brand>✈️ <b>Planelinks</b> | {t("booking_payment_navbar")}</Navbar.Brand>
                </Navbar>
                <div className="admin-dashboard-landing" style={{ marginBottom: "32px" }}>
                    <Row>
                        <Col />
                        <Col lg={6} md={9}>
                            <div className="admin-dashboard-landing">
                                <h3>{t("flights_not_covered")}</h3>
                                <br />
                                <p>{t("please_pay_for_remainder")}</p>
                                <p>{t("amount_due")} ${amountOwed.toFixed(2)}</p>
                                {payNowPercent == 0 ?
                                    (duffelIntentToken.client_token && !message && (<fieldset style={{ marginTop: "16px" }} disabled={allButtonsDisabled} >
                                        <DuffelPayments
                                            paymentIntentClientToken={duffelIntentToken.client_token}
                                            onSuccessfulPayment={handleSuccessfulPayment}
                                            onFailedPayment={handleFailurePayment}
                                        />
                                    </fieldset>))
                                    :
                                    (stripePromise && clientSecret && (
                                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                                            <CheckoutForm />
                                        </Elements>
                                    ))
                                }
                                <div id="payment-message">{message}</div>
                            </div>
                        </Col>
                        <Col />
                    </Row>
                </div>
                <hr />
                <ClientFooter />
            </Container>
        </div>
    )
}

export default ClientHoldPayPage
