import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import ClientFooter from "../utils/ClientFooter"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function InvalidLinkPage() {
    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Invalid or Expired Link</Navbar.Brand>
                </Navbar>
                <div className='admin-dashboard-landing' style={{ minHeight: "75vh" }}>
                    <h3>The link you have clicked on has either expired or is invalid.</h3>
                    <p>If you believe this is a mistake, please contact the organizer of your event.</p>
                    <div>
                        <Link to="/" className="no-style-link-light">
                            <Button variant='success' className='log-in-button' style={{ marginTop: '5vh' }}>
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </div>
            </Container>
            <hr />
            <ClientFooter />
        </div>
    )
}

export default InvalidLinkPage
