import { Link } from 'react-router-dom'
import { FiTwitter, FiMail } from 'react-icons/fi'

export default function Footer() {
    return (
        <footer className="container-fluid text-center" style={{ marginTop: '16px' }}>
            <h2 style={{ paddingTop: "5vh" }}>Connect with us!</h2>
            <a href="https://twitter.com/planelinks" target="_blank" rel="noreferrer"><FiTwitter style={{ color: "rgb(29, 161, 242)", fontSize: "40px", padding: "8px" }} /></a>
            <a href="mailto:hello@planelinks.com" target="_blank" rel="noreferrer"><FiMail style={{ color: "white", fontSize: "40px", padding: "8px" }} /></a>
            <br />
            <br />
            <Link to="/privacy-policy" className='no-style-link' style={{ color: 'lightgrey', margin: "16px" }}>Privacy Policy</Link>
            <Link to="/terms-of-use" className='no-style-link' style={{ color: 'lightgrey', margin: "16px" }}>Terms of Use</Link>
            <p style={{ color: "grey", paddingTop: "16px" }}>© 2024 Planelinks, Inc. All rights reserved.</p>
        </footer>
    )
}
