import moment from 'moment'
import { sendEmailUrl } from './Constants'

export function calculateLayover(layover_list) {
    if (layover_list.length === 0) {
        return (
            <b>Nonstop</b>
        )
    } else if (layover_list.length === 1) {
        return (
            <>
                <b>1 stop</b><br />
                {layover_list[0]}
            </>
        )
    } else {
        return (
            <>
                <b>{layover_list.length} stops</b><br />
                {layover_list.toString()}
            </>
        )
    }
}

export function calculateStopsString(layover_list) {
    if (layover_list.length === 0) {
        return "Nonstop"
    } else if (layover_list.length === 1) {
        return "1 stop"
    } else {
        return layover_list.length + " stops"
    }
}

export function layoverListString(layover_list) {
    if (layover_list.length === 0) {
        return ""
    } else if (layover_list.length === 1) {
        return layover_list[0]
    } else {
        return layover_list.toString()
    }
}

export function transformDuration(duration) {
    const d = moment.duration(duration)
    var hours = d.hours()
    if (d.days() > 0) {
        hours += 24 * d.days()
    }
    return hours + " hr " + d.minutes() + " min"
}

export function convertDateFormat(inputDateString) {
    // Create a Date object from the input string
    const inputDate = new Date(inputDateString)

    // Define arrays for days and months
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // Get the day, month, year, hours, and minutes from the input date
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const dayOfMonth = inputDate.getDate();
    const month = months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Format the date string
    const formattedDateString = `${dayOfWeek}, ${dayOfMonth} ${month} ${year}, ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return formattedDateString
}

export function getIATAFromImg(img_url) {
    return img_url.substring(76, 78)
}

export function sendConfirmEmail(data) {
    const emailParams = {
        userFirstName: data.firstName,
        userLastName: data.lastName,
        userEmail: data.user_email,
        eventName: data.eventName,
        PNR: data.order_pnr,
        outboundImg: data.outboundFlight.airline_img,
        outboundTimes: convertDateFormat(data.outboundFlight.departure_time) + " - " + convertDateFormat(data.outboundFlight.arrival_time),
        outboundAirline: data.outboundFlight.airline_name,
        outboundDuration: transformDuration(data.outboundFlight.duration),
        outboundRoute: data.outboundFlight.origin_airport + " - " + data.outboundFlight.destination_airport,
        outboundStops: calculateStopsString(data.outboundFlight.layover_airport),
        outboundStopCodes: layoverListString(data.outboundFlight.layover_airport),
        outboundFlightNums: data.outboundFlight.market_flight_no.join(", "),
        returnImg: data.returnFlight.airline_img,
        returnTimes: convertDateFormat(data.returnFlight.departure_time) + " - " + convertDateFormat(data.returnFlight.arrival_time),
        returnAirline: data.returnFlight.airline_name,
        returnDuration: transformDuration(data.returnFlight.duration),
        returnRoute: data.returnFlight.origin_airport + " - " + data.returnFlight.destination_airport,
        returnStops: calculateStopsString(data.returnFlight.layover_airport),
        returnStopCodes: layoverListString(data.returnFlight.layover_airport),
        returnFlightNums: data.returnFlight.market_flight_no.join(", "),
    }

    fetch(sendEmailUrl + '/send-email-confirm', {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailParams)
    })
}

export function displayDetailedFlightInformation(flight) {
    if (flight.layover_airport.length == 0) {
        return (
            <div>
                <div className="individual-flight-option-expand">
                    <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.airline_img} alt="Airline Logo" /></div>
                    <div><b>{convertDateFormat(flight.departure_time)} - {convertDateFormat(flight.arrival_time)}</b><br />{flight.airline_name} · {flight.market_flight_no.join(", ")}</div>
                    <div><b>{transformDuration(flight.duration)}</b><br />{flight.origin_airport}-{flight.destination_airport}</div>
                </div>
            </div>
        )
    } else {
        let rows = []
        for (let i = 0; i < flight.departure_time_list.length; i++) {
            rows.push(
                <div>
                    <div className="individual-flight-option-expand">
                        <div><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.airline_img} alt="Airline Logo" /></div>
                        <div><b>{convertDateFormat(flight.departure_time_list[i])} - {convertDateFormat(flight.arrival_time_list[i])}</b><br />{flight.airline_name} · {flight.market_flight_no[i]}</div>
                        <div><b>{transformDuration(flight.duration_list[i])}</b></div>
                    </div>
                </div>
            )
            if (i < flight.departure_time_list.length - 1) {
                rows.push(
                    <div>
                        <hr />
                        <p>Layover in {flight.layover_airport[i]} for {transformDuration(flight.layover_airport_duration[i])}</p>
                        <hr />
                    </div>
                )
            }
        }
        return rows
    }
}


export function handleExportAttendees(attendeeList) {
    let rows = []
    let header_row = ["first_name", "last_name", "email", "origin_airport", "return_airport", "is_booked", "max_cost_dollars", "actual_cost_dollars", "additional_information"]
    rows.push(header_row)
    for (var i = 0; i < attendeeList.length; i++) {
        let row = []
        row.push(attendeeList[i]["link_first_name"])
        row.push(attendeeList[i]["link_last_name"])
        row.push(attendeeList[i]["link_email"])
        row.push(attendeeList[i]["origin_airport"])
        row.push(attendeeList[i]["return_airport"])
        row.push(attendeeList[i]["is_booked"])
        row.push(attendeeList[i]["max_cost"])
        row.push(attendeeList[i]["actual_cost"])
        row.push(attendeeList[i]["additional_info"])
        rows.push(row)
    }
    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n")

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "planelinks_export.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "planelinks_export.csv"
}
