import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from '../auth/UserPool'

const AccountContext = createContext()

const Account = (props) => {
    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {

            const user = new CognitoUser({ Username, Pool })

            const authDetails = new AuthenticationDetails({ Username, Password })

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    // console.log("onSuccess: ", data)
                    resolve(data)
                },
                onFailure: (err) => {
                    // console.error("onFailure: ", err)
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    // console.log("newPasswordRequired: ", data)
                    resolve(data)
                }
            })
        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser()
        // console.log("Before logout, current user: ", user)
        if (user) {
            user.signOut()
            // console.log("user signed out")
        }
    }

    const sendCode = async (Username) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })
            user.forgotPassword({
                onSuccess: (data) => {
                    console.log("onSuccess: ", data)
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                }
            })
        })
    }

    const resetPassword = async (Username, Code, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })
            user.confirmPassword(Code, Password, {
                onSuccess: () => {
                    console.log("reset successful")
                    resolve()
                },
                onFailure: (err) => {
                    reject(err)
                }
            })
        })
    }

    return (
        <AccountContext.Provider value={{ authenticate, logout, sendCode, resetPassword }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext } 
