import i18n from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: 'en',
    resources: {
        en: {
            translation: {
                book_flight_navbar: "Book Flight",
                welcome: "Welcome",
                invited: "You have been invited to book a flight for ",
                make_selection: "Please make your selections below.",
                outbound_flights: "Outbound flights:",
                return_flights: "Returning flights:",
                select_one: "Select one.",
                confirm: "Confirm:",
                confirm_book_button: "Confirm Choices and Book",
                confirm_button: "Confirm",
                confirm_button_disabled: "Processing...",
                nonstop: "Nonstop",
                passenger_information_navbar: "Passenger Information",
                your_selection: "Your selection:",
                make_changes_question: "Need to make changes?",
                go_back: "Go back",
                passenger_information: "Passenger Information:",
                select: "Select",
                title: "Title",
                first_name: "First Name",
                last_name: "Last Name",
                email_address: "Email Address",
                phone_number: "Phone Number",
                gender: "Gender",
                male: "Male",
                female: "Female",
                date_of_birth: "Date of Birth",
                additional_info: "Additional Information",
                error_title: "Please select a title.",
                error_first_name: "Please provide a first name.",
                error_last_name: "Please provide a last name.",
                error_email_address: "Please provide a valid email address.",
                error_phone_number: "Please provide a valid phone number.",
                error_gender: "Please select a gender.",
                error_date_of_birth: "Please provide a valid date of birth.",
                booking_payment_navbar: "Booking Payment",
                flights_not_covered: "The flights you have selected are not entirely covered by your organization's policy.",
                please_pay_for_remainder: "Please pay for the remaining portion below.",
                amount_due: "Amount Due: ",
                booking_confirmation_navbar: "Booking Confirmation",
                congrats_flights_booked: "Congratulations! Your flights have been booked.",
                confirmation_email_to_send: "A confirmation email will be sent to your provided email shortly.",
                have_questions: "Have questions?",
                reach_support: "Please reach out to",
                confirmation_code: "Confirmation code",
                your_flights: "Your flights",
                print_page: "Print Page",
                back_to_homepage: "Back to Homepage",
                convert_text_bottom: "Use Planelinks for your event. Get started today at ",
            },
        },
        es: {
            translation: {
                book_flight_navbar: "Reservar Vuelos",
                welcome: "Bienvenido",
                invited: "Se le ha invitado a reservar un vuelo para ",
                make_selection: "Por favor haga sus selecciones a continuación.",
                outbound_flights: "Vuelos de salida:",
                return_flights: "Vuelos de regreso:",
                select_one: "Seleccione uno.",
                confirm: "Confirmar:",
                confirm_book_button: "Confirmar Opciones y Reservar",
                confirm_button: "Confirmar",
                confirm_button_disabled: "Procesando...",
                nonstop: "Vuelo directo",
                passenger_information_navbar: "Información del Pasajero",
                your_selection: "Tu selección:",
                make_changes_question: "¿Necesitas hacer cambios?",
                go_back: "Volver atrás",
                passenger_information: "Información del Pasajero:",
                select: "Seleccionar",
                title: "Título",
                first_name: "Nombre",
                last_name: "Apellido",
                email_address: "Dirección de correo electrónico",
                phone_number: "Número de teléfono",
                gender: "Género",
                male: "Masculino",
                female: "Femenino",
                date_of_birth: "Fecha de Nacimiento",
                additional_info: "Información Adicional",
                error_title: "Por favor seleccione un título.",
                error_first_name: "Por favor proporcione un nombre.",
                error_last_name: "Por favor proporcione un apellido.",
                error_email_address: "Por favor proporcione una dirección de correo electrónico válida.",
                error_phone_number: "Por favor proporcione un número de teléfono válido.",
                error_gender: "Por favor seleccione un género.",
                error_date_of_birth: "Por favor proporcione una fecha de nacimiento válida.",
                booking_payment_navbar: "Pago de reserva",
                flights_not_covered: "Los vuelos que ha seleccionado no están totalmente cubiertos por la política de su organización.",
                please_pay_for_remainder: "Por favor pague la porción restante a continuación.",
                amount_due: "Cantidad Adeudada: ",
                booking_confirmation_navbar: "Confirmación de reserva",
                congrats_flights_booked: "¡Felicidades! Sus vuelos han sido reservados.",
                confirmation_email_to_send: "En breve se enviará un correo electrónico de confirmación a su correo electrónico proporcionado.",
                have_questions: "¿Tiene preguntas?",
                reach_support: "Comuníquese con ",
                confirmation_code: "Código de confirmación",
                your_flights: "Tus vuelos",
                print_page: "Imprimir página",
                back_to_homepage: "Volver a la página de inicio",
                convert_text_bottom: "Utilice Planelinks para su evento. Comience hoy en ",
            },
        },
    }
})
