import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import AdminFooter from '../utils/AdminFooter'
import { baseUrl } from "../utils/Constants"
import { AccountContext } from "../auth/Account"
import Pool from '../auth/UserPool'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminCreateNewEventsPage() {
    const location = useLocation()
    const navigation = useNavigate()
    const { logout } = useContext(AccountContext)

    const [eventName, setEventName] = useState("")
    const [eventLocation, setEventLocation] = useState("")
    const [eventImg, setEventImg] = useState("")
    const [eventOrganizer, setEventOrganizer] = useState("") // Obtained from previous page
    const [email, setEmail] = useState("")
    const [organizationID, setOrganizationID] = useState(undefined)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [flyInOffset, setFlyInOffset] = useState(0)
    const [flyOutOffset, setFlyOutOffset] = useState(0)
    const [prefAirports, setPrefAirports] = useState("")
    const [payNowPercent, setPayNowPercent] = useState(100)
    const [allowEconomy, setAllowEconomy] = useState(true)
    const [allowBusiness, setAllowBusiness] = useState(false)
    const [allowFirst, setAllowFirst] = useState(false)

    const [btnDisabled, setBtnDisabled] = useState(false)
    const [show, setShow] = useState(false)
    const [validated, setValidated] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [authToken, setAuthToken] = useState("")

    useEffect(() => {
        if (location.state?.organizationName && location.state?.organizationID && location.state?.userName) {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        navigation('/sign-in')
                    } else {
                        setAuthToken(session.accessToken.jwtToken)
                        setEventOrganizer(location.state.organizationName)
                        setOrganizationID(location.state.organizationID)
                        setEmail(location.state.userName)
                    }
                })
            }
        } else {
            navigation('/dashboard')
        }
    }, [])

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setBtnDisabled(true)

            var flyInDate = new Date(startDate)
            flyInDate.setDate(flyInDate.getDate() - flyInOffset)
            flyInDate = flyInDate.toISOString().split('T')[0]

            var flyOutDate = new Date(endDate)
            flyOutDate.setDate(flyOutDate.getDate() - (-1 * flyOutOffset))
            flyOutDate = flyOutDate.toISOString().split('T')[0]

            const eventData = {
                event_id: crypto.randomUUID(),
                org_id: organizationID,
                event_name: eventName,
                event_location: eventLocation,
                event_img: eventImg,
                event_organizer: eventOrganizer,
                event_start: startDate,
                event_end: endDate,
                fly_in_date: flyInDate,
                fly_out_date: flyOutDate,
                pref_airports: prefAirports,
                pay_now_percent: payNowPercent,
                allow_econ: true, // allowEcon
                allow_biz: false, // allowBiz
                allow_first: false, // allowFirst
            }

            fetch(baseUrl + '/admin/create-new-event', {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(eventData)
            })
                .then(results => results.json())
                .then(data => {
                    // console.log(data)
                    setBtnDisabled(false)
                    navigation('/manage-event', { state: { event_id: data.event_id, userName: email, organizationName: eventOrganizer } })
                })
        }
        setValidated(true)
    }

    function handleLogout() {
        logout()
        navigation('/sign-in')
    }

    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Create New Event</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end" >
                        <Button variant="success" className="log-in-button" style={{ marginRight: '32px' }} onClick={handleShow}>
                            Back to Dashboard
                        </Button>
                        <Button variant="dark" className="log-in-button" onClick={() => handleLogout()}>
                            Log Out
                        </Button>
                    </Navbar.Collapse>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <h3>Create New Event</h3>
                    <Form noValidate onSubmit={(e) => handleSubmit(e)} validated={validated}>
                        <Row>
                            <Col md={6}>
                                <div className='dark-rect-round'>
                                    <Form.Group className='mb-3' controlId='formBasicEventName' onChange={(e) => { setEventName(e.target.value) }}>
                                        <Form.Label>Event Name</Form.Label>
                                        <Form.Control type="text" placeholder='Event Name' required />
                                        <Form.Control.Feedback type='invalid'>Please provide an event name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicEventLocation' onChange={(e) => { setEventLocation(e.target.value) }}>
                                        <Form.Label>Event Location</Form.Label>
                                        <Form.Control type="text" placeholder='Event Location' required />
                                        <Form.Control.Feedback type='invalid'>Please provide an event location.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicEventStart' onChange={(e) => { setStartDate(e.target.value) }}>
                                        <Form.Label>Event Start</Form.Label>
                                        <Form.Control type="date" placeholder='Event Start' required />
                                        <Form.Control.Feedback type='invalid'>Please provide an event start date.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicFlyInDate' onChange={(e) => { setFlyInOffset(e.target.value) }}>
                                        <Form.Label>Fly In Date</Form.Label>
                                        <Form.Select as="select" type="select" required>
                                            <option value="">Select</option>
                                            <option value={0}>Same day as event start</option>
                                            <option value={1}>One day before event start</option>
                                            <option value={2}>Two days before event start</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>Please select a fly in date.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicEventEnd' onChange={(e) => { setEndDate(e.target.value) }}>
                                        <Form.Label>Event End</Form.Label>
                                        <Form.Control type="date" placeholder='Event End' required />
                                        <Form.Control.Feedback type='invalid'>Please provide an event end date.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formBasicFlyOutDate' onChange={(e) => { setFlyOutOffset(e.target.value) }}>
                                        <Form.Label>Fly Out Date</Form.Label>
                                        <Form.Select as="select" type="select" required>
                                            <option value="">Select</option>
                                            <option value={0}>Same day as event ends</option>
                                            <option value={1}>One day after event ends</option>
                                            <option value={2}>Two days after event ends</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>Please select a fly out date.</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="dark-rect-round">
                                    <Form.Group className="mb-3" controlId="formBasicAirport" onChange={(e) => { setPrefAirports(e.target.value) }}>
                                        <Form.Label>Preferred Arrival Airport(s)?</Form.Label>
                                        <Form.Control type="text" placeholder="JFK, LGA, EWR" required />
                                        <Form.Control.Feedback type='invalid'>Please provide a preferred arrival airport.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPayNowPercent" onChange={(e) => { setPayNowPercent(e.target.value) }}>
                                        <Form.Label>Percentage Paid Now</Form.Label>
                                        <Form.Control type="number" placeholder="100" min={0} max={100} required />
                                        <Form.Control.Feedback type='invalid'>Please provide an upfront payment percentage.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicAllowEconomy" onChange={(e) => { setAllowEconomy(e.target.value) }}>
                                        <Form.Label>Allow Economy Class? (feature in progress)</Form.Label>
                                        <Form.Select disabled>
                                            {/* <option>Select</option> */}
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicAllowBusiness" onChange={(e) => { setAllowBusiness(e.target.value) }}>
                                        <Form.Label>Allow Business Class? (feature in progress)</Form.Label>
                                        <Form.Select disabled>
                                            {/* <option>Select</option> */}
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicAllowFirst" onChange={(e) => { setAllowFirst(e.target.value) }}>
                                        <Form.Label>Allow First Class? (feature in progress)</Form.Label>
                                        <Form.Select disabled>
                                            {/* <option>Select</option> */}
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='text-align-center'>
                                    <Button variant='success' type='submit' className='log-in-button' disabled={btnDisabled}>
                                        Create Event
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <hr />
                <AdminFooter />
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Return to Dashboard?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Changes will not be saved.</Modal.Body>
                <Modal.Footer>
                    <Link to='/dashboard' className="no-style-link-light">
                        <Button variant="primary" onClick={handleClose}>
                            Confirm
                        </Button>
                    </Link>
                    <Button variant="secondary" onClick={handleClose}>
                        Keep Editing
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminCreateNewEventsPage
