import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import i18n from 'i18next'

export default function ClientFooter() {
    function handleClick(lang) {
        i18n.changeLanguage(lang)
    }

    return (
        <footer className='text-align-center' style={{ backgroundColor: "white" }}>
            <Row>
                <Col md={4}>
                    <p>Need help?<br />
                        <Link to="mailto:support@planelinks.com">support@planelinks.com</Link></p>
                </Col>
                <Col md={4}>
                    <Link to="/privacy-policy" className='no-style-link' style={{ margin: "16px" }}>Privacy Policy</Link>
                    <Link to="/terms-of-use" className='no-style-link' style={{ margin: "16px" }}>Terms of Use</Link>
                    <p style={{ color: "grey", paddingTop: "8px" }}>© 2024 Planelinks, Inc. All rights reserved.</p>
                </Col>
                <Col md={4}>
                    <p>Language<br />
                        <Button variant="link" onClick={() => handleClick("en")}>English</Button>
                        <Button variant="link" onClick={() => handleClick("es")}>Español</Button></p>
                </Col>
            </Row>
        </footer>
    )
}
