import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from '../utils/Footer'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function BookDemoPage() {
    return (
        <div className='home'>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/" className='landing-text'>✈️ <b>Planelinks</b></Navbar.Brand>
                    <Navbar.Collapse className='justify-content-end'>
                        <Container>
                            <Nav className="me-auto">
                                <Nav.Link href="/#features" className='landing-text navbar-margin'>Features</Nav.Link>
                                <Nav.Link href="/book-demo" className='landing-text navbar-margin'>Book Demo</Nav.Link>
                                <Nav.Link href="/pricing" className='landing-text navbar-margin'>Pricing</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar.Collapse>
                    <Link to="/sign-in" className='no-style-link d-flex landing-text navbar-margin'>Log In</Link>
                    <Link to="/sign-up" className='no-style-link d-flex'>
                        <Button variant='dark' className='log-in-button'>
                            Sign Up
                        </Button>
                    </Link>
                </Navbar>
                <div className='landing-text'>
                    <p className='slogan'>
                        <strong>
                            Book a Demo
                        </strong>
                    </p>
                </div>
                <Row>
                    <Col />
                    <Col>
                        <iframe src="https://zcal.co/i/3hj4GY2u?embed=1&embedType=iframe" loading="lazy" style={{border: "none", minWidth: "320px", minHeight: "544px", height: "950px", width: "768px"}} id="zcal-invite"></iframe>
                    </Col>
                    <Col />
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default BookDemoPage
