import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { AccountContext } from '../auth/Account'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LandingPage.css';
import '../styles/SignInPage.css'

function PasswordResetPage() {
    const { sendCode } = useContext(AccountContext)
    const navigation = useNavigate()

    const [email, setEmail] = useState("")
    const [validated, setValidated] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState("")

    function handleSubmit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            sendCode(email)
                .then(() => {
                    navigation('/reset-password-2')
                })
                .catch(err => {
                    setLoginErrorMessage(err.toString())
                })
        }
        setValidated(true)
    }

    return (
        <div className='home'>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/" style={{ color: 'white' }}>✈️ <b>Planelinks</b></Navbar.Brand>
                </Navbar>
                <div className='landing-text'>
                    <p className='slogan'>
                        Let's get you back on your way.
                    </p>
                </div>
                <Row>
                    <Col />
                    <Col>
                        <div className='grey-rect-round' style={{ minHeight: "30vh", width: '25em' }}>
                            <Form noValidate onSubmit={(e) => { handleSubmit(e) }} validated={validated}>
                                <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setEmail(e.target.value) }}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" placeholder='Email Address' required />
                                    <Form.Control.Feedback type='invalid'>Please provide a valid email address.</Form.Control.Feedback>
                                </Form.Group>
                                <div style={{ color: "red" }}>{loginErrorMessage}</div>
                                <div className='text-align-center'>
                                    <Button variant='light' type='submit' className='sign-in-button'>
                                        Send Recovery Code
                                    </Button>
                                    <br />
                                    <br />
                                    <Form.Text><Link to="/sign-in" className='no-style-link'>Back to Login Page</Link></Form.Text>
                                </div>
                            </Form>
                        </div>
                    </Col>
                    <Col />
                </Row>
            </Container>
        </div>
    )
}

export default PasswordResetPage
