import { useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { PaymentElement } from "@stripe/react-stripe-js"
import Button from "react-bootstrap/Button"

import { baseUrl } from "../utils/Constants"
import { sendConfirmEmail } from "./utils"

export default function CheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()
    const navigation = useNavigate()
    const location = useLocation()

    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const bookingData = location.state.booking_data
    const firstName = location.state.firstName
    const lastName = location.state.lastName
    const email = location.state.email
    const eventName = location.state.eventName
    const outboundFlight = location.state.outboundFlight
    const returnFlight = location.state.returnFlight

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setIsProcessing(true)

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/booking-finish`
            },
            redirect: "if_required"
        })

        if (error) {
            setMessage(error.message)
        } else if (paymentIntent.status === "succeeded") {
            console.log(bookingData)
            setIsProcessing(true)
            fetch(baseUrl + "/client/create-flight-booking", {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bookingData)
            })
                .then(results => {
                    console.log("status", results.status)
                    if (results.status === 400 || results.status === 404) {
                        return navigation('/invalid-expired-link') // TODO: redirect back to start of booking
                    }
                    return results.json()
                })
                .then(data => {
                    console.log("Data", data)
                    if (data) {
                        const email_data = {
                            firstName: firstName,
                            lastName: lastName,
                            user_email: email,
                            eventName: eventName,
                            order_pnr: data.order_pnr,
                            outboundFlight: outboundFlight,
                            returnFlight: returnFlight,
                        }
                        sendConfirmEmail(email_data)
                        navigation("/booking-finish", { state: { link_id: location.state.id } })
                        // setIsProcessing(false)
                    }
                })
        } else {
            setMessage("Error: unexpected state")
        }

        // setIsProcessing(false)
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="text-align-center" style={{ marginTop: "2vh" }}>
                <Button disabled={isProcessing} type="submit">
                    {isProcessing ? "Processing ... " : "Pay Now"}
                </Button>
            </div>
            <div id="payment-message">{message}</div>
        </form>
    )
}
