import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import PasswordResetPage from './pages/PasswordResetPage'
import PasswordResetFinalPage from './pages/PasswordResetFinalPage'
import AdminAddPeopleToEventPage from './pages/AdminAddPeopleToEventPage'
import AdminCreateNewEventPage from './pages/AdminCreateNewEventPage'
import AdminDashboardLandingPage from './pages/AdminDashboardLandingPage'
import AdminManageEventPage from './pages/AdminManageEventPage'
import AdminViewEventPage from './pages/AdminViewEventPage'
import AdminAddLinksPage from './pages/AdminAddLinksPage'
import AdminPayFlightsPage from './pages/AdminPayFlightsPage'
import ClientBookingPage from './pages/ClientBookingPage'
import ClientInformationPage from './pages/ClientInformationPage'
import ClientHoldPayPage from './pages/ClientHoldPayPage'
import ClientFinishPage from './pages/ClientFinishPage'
import NotFoundPage from './pages/NotFoundPage'
import BookDemoPage from './pages/BookDemoPage'
import PricingPage from './pages/PricingPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import TermsOfUsePage from './pages/TermsOfUsePage'
import { Account } from './auth/Account'
import Status from './auth/Status'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import InvalidLinkPage from './pages/InvalidLinkPage'

function App() {
    return (
        <Account>
            <Router>
                <Routes>
                    <Route element={<Status />} >
                        {/* Admin pages */}
                        <Route path="/add-attendee" element={<AdminAddPeopleToEventPage />} />
                        <Route path="/create-new-event" element={<AdminCreateNewEventPage />} />
                        <Route path="/dashboard" element={<AdminDashboardLandingPage />} />
                        <Route path="/manage-event" element={<AdminManageEventPage />} />
                        <Route path="/view-event" element={<AdminViewEventPage />} />
                        <Route path="/add-links" element={<AdminAddLinksPage />} />
                        <Route path="/prepay-flights" element={<AdminPayFlightsPage />} />
                    </Route>

                    {/* Client Pages */}
                    <Route path="/book-flight" element={<ClientBookingPage />} />
                    <Route path="/booking-info" element={<ClientInformationPage />} />
                    <Route path="/booking-pay" element={<ClientHoldPayPage />} />
                    <Route path="/booking-finish" element={<ClientFinishPage />} />
                    <Route path="/invalid-expired-link" element={<InvalidLinkPage />} />

                    {/* Auth pages */}
                    <Route path="/sign-in" element={<SignInPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/reset-password" element={<PasswordResetPage />} />
                    <Route path="/reset-password-2" element={<PasswordResetFinalPage />} />

                    {/* Landing page */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/book-demo" element={<BookDemoPage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/terms-of-use" element={<TermsOfUsePage />} />

                    {/* Catch all */}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </Account>
    )
}

export default App;
