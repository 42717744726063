import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function AdminFooter() {
    return (
        <footer className='text-align-center' style={{ backgroundColor: "white" }}>
            <Row>
                <Col md={4} />
                <Col md={4}>
                    <p>Need help?<br />Email us at <Link to="mailto:support@planelinks.com">support@planelinks.com</Link></p>
                    <Link to="/privacy-policy" className='no-style-link' style={{ margin: "16px" }}>Privacy Policy</Link>
                    <Link to="/terms-of-use" className='no-style-link' style={{ margin: "16px" }}>Terms of Use</Link>
                    <p style={{ color: "grey", paddingTop: "8px" }}>© 2024 Planelinks, Inc. All rights reserved.</p>
                </Col>
                <Col md={4} />
            </Row>
        </footer>
    )
}
